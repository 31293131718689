$font-bold-name: "Inter Bold";
$font-semibold-name: "Inter SemiBold";
$font-regular-name: "Inter Regular";
$font-light-name: "Inter Light";

$font-light: $font-light-name, Helvetica, sans-serif;
$font-bold: $font-bold-name, Helvetica, sans-serif;
$font-semibold: $font-semibold-name, Helvetica, sans-serif;
$font-regular: $font-regular-name, Helvetica, sans-serif;

@mixin font-bold($color, $size: 14px, $line-height: 24px) {
    font-family: $font-bold;
    font-style: normal;
    font-weight: 400;
    font-size: $size;
    line-height: $line-height;

    color: $color;
}

@mixin font-regular($color, $size: 14px, $line-height: 24px) {
    font-family: $font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: $size;
    line-height: $line-height;

    color: $color;
}

@mixin font-header($color, $size: 14px, $line-height: 24px) {
    font-family: $font-regular;
    font-style: normal;
    font-weight: 700;
    font-size: $size;
    line-height: $line-height;

    color: $color;
}

@mixin font-light($color, $size: 14px, $line-height: 24px) {
    font-family: $font-regular;
    font-style: normal;
    font-weight: 300;
    font-size: $size;
    line-height: $line-height;

    color: $color;
}
