@import "fonts";
@import "colors";

$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin animate($speed: 0.3s, $target: all, $timing: ease) {
    transition: $target $speed $timing;
}
