@import "common/fonts";

$font-bold-src: url("./../assets/fonts/Inter-Bold.ttf");

@font-face {
    font-family: $font-bold-name;
    font-weight: normal;
    src: $font-bold-src;
    font-display: swap;
}

$font-semibold-src: url("./../assets/fonts/Inter-SemiBold.ttf");

@font-face {
    font-family: $font-semibold-name;
    font-weight: normal;
    src: $font-semibold-src;
    font-display: swap;
}

$font-regular-src: url("./../assets/fonts/Inter-Regular.ttf");

@font-face {
    font-family: $font-regular-name;
    font-weight: normal;
    src: $font-regular-src;
    font-display: swap;
}

$font-light-src: url("./../assets/fonts/Inter-Light.ttf");

@font-face {
    font-family: $font-light-name;
    font-weight: normal;
    src: $font-light-src;
    font-display: swap;
}
