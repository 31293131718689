.layout-clean {
    .content-container {
        padding: 56px 0;

        & > nav {
            padding-bottom: 24px;
            display: flex;

            > ul {
                list-style: none;
                margin: 0;
                padding: 0;

                display: inline-block;

                li {
                    display: inline-block;

                    &,
                    * {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 32px;
                    }

                    span {
                    }

                    &:after {
                        content: "/";
                        margin: 0 8px;
                    }

                    &:last-child {
                        &:after {
                            content: "";
                        }
                    }
                }
            }

            > .back-link {
                a {
                    display: inline-block;

                    font-weight: normal;
                    font-size: 18px;
                    line-height: 32px;
                    color: $text-blue2;

                    &:before {
                        content: "Back Arrow";
                        color: rgba(#000, 0);

                        display: inline-block;
                        width: 16px;
                        height: 16px;

                        float: left;
                        margin-top: 8px;
                        margin-right: 20px;

                        background: url("./../../assets/imgs/icons/arrow-left.svg") no-repeat center;
                    }
                }
            }
        }
    }
}
