@import "../common";

.layout-default {
    min-height: 100vh;
    display: flex;

    header {
        background-color: $common-white;
    }

    main {
        flex-grow: 1;
        position: relative;
    }

    footer {
        padding: 120px 0;
    }

    @include mobile {
        main {
            min-height: 85vh;
        }
        footer {
            padding: 40px 0;
        }
    }
}
