// main sass stylesheet

#root {
    position: relative;
    min-height: 100vh;

    @import "common";
    @import "common/form";
    @import "layout";

    @include font-regular($text-color1);

    a {
        text-decoration: none;
    }
}
