@import "../common";

.btn {
    &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
    }

    &.block {
        display: block;
        width: 100%;
    }

    &.btn-primary {
        border: none;
        background-color: $bg-orange !important;

        @include font-header($common-white, 16px, 24px);
    }

    &.btn-light {
        @include font-regular($text-orange, 14px, 20px);

        &[class~="icon-eye"] {
            text-align: left;

            &:after {
                content: "";
                width: 16px;
                height: 16px;
                float: right;
            }
        }

        &.icon-eye {
            &:after {
                background: url("./../../assets/imgs/components/form/icon-eye.svg") center no-repeat;
            }
        }
    }
}
