$bg-blue: #1c3282;
$bg-green: #23a9ab;
$bg-green2: #e4f7ec;
$bg-orange: #f17920;
$bg-gray: #d4d5d8;
$bg-gray2: #f8f8f8;
$bg-red: rgba(#f00, 0.1);
$bg-green: rgba(35, 169, 171, 0.1);

$border-red: #f00;
$border-green: #59c378;
$border-gray: #e0e0e0;
$border-gray2: $bg-gray;

// text
$text-color1: #2b2d33;
$text-color2: #2b2d32;
$text-color3: rgba(43, 45, 50, 0.8);

$text-gray: rgba(43, 45, 51, 0.8);
$text-gray2: #dbdde1;
$text-gray3: #54575f;

$text-blue: #1c3282;
$text-blue2: #20465a;

$text-green: #23a9ab;

$text-orange: #f17920;

$text-error: #c0492c;

$text-orange: $bg-orange;

// common
$common-white: #ffffff;
$common-black: #000000;

$common-light: #ebeff4;

$common-shadow: rgba(39, 39, 39, 0.24);

$common-disabled: #d9d9d9;
